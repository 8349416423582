import { Alert } from "@mui/material";

export default function SpecialMessage(props: { content: string }) {

  return props.content === '' ? <></> : (
    <Alert severity="error"
      sx={{
        height: "300px",
        width: '100%',
        fontSize: "1.5em"
      }}>
      <div dangerouslySetInnerHTML={{ __html: props.content }} />
    </Alert>
  );
}