import ServiceStatusGrid from './components/ServiceStatusGrid';

import './App.css';

function App() {
  return (
    <ServiceStatusGrid />
  );
}

export default App;
