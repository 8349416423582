import { useEffect, useState } from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { getFormattedDateTime, getFormattedDuration } from 'utility';
import SETTINGS from 'configuration';
import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress, Typography } from "@mui/material";

import { Notification, notificationsColumnsDefinition } from '../../Notifications';
import { Session, sessionsColumnsDefinition } from '../../SystemSessions';
import { ledColor } from 'utility';

type StatusResponseObj = {
    status: [{
        node: {
            Title: string
            Status: string
        }
    }] | null;
    sessions: [{ node: Session }] | null;
    notifications: [{ node: Notification }] | null;
}

const fetchStatusDetails = async (service: string): Promise<StatusResponseObj> => {
    const res = await fetch(SETTINGS.url.status + "/" + encodeURIComponent(service));
    return await res.json();
}

function StatusDetailsDialog(props: { ServiceName: string | null, onClose: () => void, UTC: boolean }) {

    const { ServiceName, onClose, UTC } = props;
    const [popupOpen, setPopupOpen] = useState<boolean>(false);

    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [loadedData, setLoadedData] = useState<StatusResponseObj | null>(null);
    const [hasError, setHasError] = useState<string | null>(null);

    const numItemsToShow = 15;

    const getData = (serviceName: string) => {

        setIsDataLoading(true);

        fetchStatusDetails(serviceName)
            .then((res) => {
                setLoadedData(res)
            })
            .catch((error: unknown) => {
                if (error instanceof Error) {
                    setHasError(error.message);
                } else {
                    setHasError("Unknown error");
                }
            })
            .finally(() => {
                setIsDataLoading(false);
            })

    }

    useEffect(() => {
        if (ServiceName) {
            setPopupOpen(true)
            getData(ServiceName)
        }
    }, [ServiceName])

    const handleClose = () => {
        setPopupOpen(false);
        setLoadedData(null);
        setHasError(null);
        onClose();
    };


    let statusOutput = <>
        <h3>{ServiceName}</h3>
        no data
    </>;

    let sessionsOutput, notificationsOutput = <>
    </>

    if (loadedData !== null) {
        let s = (loadedData.status !== null ? '' + loadedData.status[0].node.Status : 'no data')

        statusOutput = (<>
            <h3>
                <Chip
                    icon={<CircleIcon color={ledColor(s)} />}
                    label={"STATUS: " + s.toUpperCase()}
                    variant="outlined"
                />
            </h3>
        </>);

        const notificationsRows = loadedData.notifications === null ? [] : loadedData.notifications.slice(0, numItemsToShow).map(
            (item) => {
                return {
                    id: item.node.Nid,
                    postDate: item.node['Post date'],
                    title: item.node['Title'],
                    // startTime: item.node['Start Time'] //not needed in this table
                    services: '', //  not needed in this table
                    type: item.node['Type'],
                    action: item.node['Action'] ?? ''
                }
            }
        );

        const notificationsColumns = [...notificationsColumnsDefinition]
        notificationsColumns[0].valueFormatter = (row) => getFormattedDateTime(row, props.UTC)


        notificationsOutput = (
            <DataGrid
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            services: false,
                            action: false
                        }
                    },
                    sorting: {
                        sortModel: [{ field: 'startTime', sort: 'desc' }],
                    },
                    pagination: {
                        paginationModel: { pageSize: 15, page: 0 },
                    },
                }}
                rows={notificationsRows}
                columns={notificationsColumns}
                rowHeight={42}
                autoHeight={true}
                disableRowSelectionOnClick
                columnHeaderHeight={0}
                hideFooter={true}
            />)

        const sessionsRows = loadedData.sessions === null ? [] : loadedData.sessions.slice(0, numItemsToShow).map(
            (item) => {
                return {
                    id: item.node.Nid,
                    title: item.node['Title'],
                    startTime: item.node['Start Time'],
                    services: '', //  not needed in this table
                    duration: getFormattedDuration(item.node.Duration),
                    userImpact: item.node.Impact !== '' ? 'Yes' : ''
                }
            }
        );

        const sessionsColumns = [...sessionsColumnsDefinition]
        sessionsColumns[0].valueFormatter = (row) => getFormattedDateTime(row, UTC)

        sessionsOutput = (
            <DataGrid
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            services: false,
                            userImpact: false
                        }
                    },
                    sorting: {
                        sortModel: [{ field: 'startTime', sort: 'desc' }],
                    },
                    pagination: {
                        paginationModel: { pageSize: 15, page: 0 },
                    },
                }}
                rows={sessionsRows}
                columns={sessionsColumns}
                rowHeight={42}
                autoHeight={true}
                disableRowSelectionOnClick
                columnHeaderHeight={0}
                hideFooter={true}
            />)
    }

    return (
        <Dialog fullWidth={true} maxWidth={'md'} open={popupOpen} onClose={handleClose}>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle sx={{
                bgcolor: 'black',
                color: 'white',
                textTransform: 'none',
                fontWeight: 'bold',
                paddingRight: '40px',

            }}  >{ServiceName}</DialogTitle>
            <DialogContent>
                {
                    isDataLoading &&
                    <CircularProgress sx={{ display: "block", margin: "10px auto" }} />
                }
                {
                    hasError &&
                    <Box>
                        <Typography color="error">
                            error fetching Status data for service {ServiceName}
                        </Typography>
                    </Box>
                }
                {
                    !hasError && loadedData && <>
                        {statusOutput}
                        <Box sx={{ width: '100%' }}>
                            <div className='header-subdued-note'>(latest {numItemsToShow} items)</div>
                            <h3>Notifications </h3>
                            {notificationsOutput}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <div className='header-subdued-note'>(latest {numItemsToShow} items)</div>
                            <h3>System sessions</h3>
                            {sessionsOutput}
                        </Box>
                    </>
                }
            </DialogContent>
        </Dialog>
    )

}

export default StatusDetailsDialog;