import { createTheme, PaletteColor, PaletteColorOptions } from "@mui/material";
import { green, yellow } from "@mui/material/colors";

declare module '@mui/material/styles' {
  interface Palette {
    link: PaletteColor,
  }

  interface PaletteOptions {
    link?: PaletteColorOptions,
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#e08d23',
      contrastText: '#ffffff'
    },
    link: {
      main: '#008099'
    },
    success: {
      main: green[700],
      contrastText: '#ffffff'
    },
    warning: {
      main: yellow[800],
      contrastText: '#ffffff'
    },
  },
  typography: {
    fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontSize: 14,
    h1: {
      fontSize: '2.4rem',
      fontWeight: 300
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 300
    },
    h3: {
      fontSize: '1.825rem',
      fontWeight: 300
    },
    h4: {
      fontSize: '1.3rem',
      fontWeight: 300
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 300
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 300,
      textTransform: 'uppercase'
    }
  }
});

export default theme;
