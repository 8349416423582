import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { getFormattedDateTime } from 'utility';
import SETTINGS from 'configuration';
import { ResponseObj } from '../Notifications';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { CircularProgress, Typography } from "@mui/material";


const fetchDetails = (nid: string): Promise<ResponseObj<{ Title: string }>> => {
    return fetch(SETTINGS.url.details + "/" + nid)
        .then((res) => res.json());
}

function DetailsDialog(props: { UTC: boolean, Nid: string | null, onClose: () => void }) {

    const { Nid, onClose, UTC } = props;

    const [popupOpen, setPopupOpen] = useState<boolean>(false);

    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [loadedData, setLoadedData] = useState<{ Title: string } | null>(null);
    const [hasError, setHasError] = useState<string | null>(null);

    const getData = (nid: string) => {
        setIsDataLoading(true);

        fetchDetails(nid)
            .then((res) => {
                setLoadedData(res.nodes[0].node)
            })
            .catch((error: unknown) => {
                if (error instanceof Error) {
                    setHasError(error.message);
                } else {
                    setHasError("Unknown error");
                }
            })
            .finally(() => {
                setIsDataLoading(false);
            })
    }

    useEffect(() => {
        setPopupOpen(true)
        if (Nid) { getData(Nid) }
    }, [Nid])

    const handleClose = () => {
        setPopupOpen(false);
        setLoadedData(null);
        setHasError(null);
        onClose();
    };

    if (Nid === null) return null;

    return (
        <Dialog fullWidth={true} open={popupOpen} onClose={handleClose}>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            {
                isDataLoading &&
                <>
                    <DialogTitle>(loading)</DialogTitle>
                    <CircularProgress sx={{ display: "block", margin: "10px auto" }} />
                </>
            }

            {
                hasError &&
                <>
                    <DialogTitle>Error!</DialogTitle>
                    <DialogContent>
                        <Typography color="error">
                            Something went wrong while fetching data...
                        </Typography>
                    </DialogContent>
                </>
            }

            {
                loadedData &&
                <>
                    <DialogTitle sx={{
                        bgcolor: 'black',
                        color: 'white',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        paddingRight: '40px',
                        paddingLeft: '40px'

                    }}  >{loadedData.Title}</DialogTitle>
                    <DialogContent>
                        <List>
                            {Object.entries(loadedData).map(([key, value]) => {
                                const timezone = UTC ? " UTC" : " Local";
                                return (
                                    <ListItem key={key}>
                                        <ListItemText
                                            primary={key}
                                            secondary={
                                                (key !== 'Date') ? value : (getFormattedDateTime(value, UTC) + timezone)
                                            } />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </DialogContent>
                </>
            }
        </Dialog>
    )
}

export default DetailsDialog
