/* settings */

const API_BASE_URL = process.env['REACT_APP_API_BASE_URL'];

const SETTINGS = Object.freeze({
  'url': {
    'base': API_BASE_URL,
    'sessions': `${API_BASE_URL}/sessions`,
    'notifications': `${API_BASE_URL}/notifications`,
    'status': `${API_BASE_URL}/status`,
    'details': `${API_BASE_URL}/details`,
    'special_message': 'https://www.ecmwf.int/jsonapi/node/status_page?filter%5Bnid%5D=29030'
  },
  'cookies': {
    'timezone': 'UTC'
  }
});

export default SETTINGS;